<template>
	<section class="news-overview center">
		<div style="width:100%; max-width:1000px;">
			<quote-title label="焦點消息" />
			<br>
			<draggable v-model="list" class="row-grid">
				<template v-for="(item, i) in _newsList">
					<div class="grid-item col rwd-l-3 rwd-m-4 rwd-s-6" align-y="between" :key="item.id || i">
						<div class="col">
							<thumb :src="item.thumb" />
							<br>
							<pre v-if="item.title" style="text-align:center;">{{item.title}}</pre>
						</div>
						<router-link :to="`/manager/news/${item.id}`">
							<el-button type="primary" size="mini" round>編輯</el-button>
						</router-link>
					</div>
				</template>
				<div class="center rwd-l-3 rwd-m-4 rwd-s-6">
					<router-link to="/manager/news/new">
						<el-button type="success" icon="el-icon-plus" round>新增</el-button>
					</router-link>
				</div>
			</draggable>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Draggable: () => import("vuedraggable"),
		Thumb: () => import("@/components/thumb")
	},
	computed: {
		...mapState("news", ["_newsList"]),
		list: {
			get() {
				return this._newsList;
			},
			set(value) {
				this._sortList(value);
			}
		},
	},
	methods: {
		...mapActions("news", [
			"_getList",
			"_sortList",
		]),
	},
	async created() {
		await this._getList();
	}
}
</script>

<style lang="scss" scoped>
.row-grid {
	margin: -10px;

	.grid-item {
		padding: 10px;
		cursor: move;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}
}
</style>